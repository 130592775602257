import styled from "styled-components";

export const MiniCardLinkWrapper = styled.li`
  display: flex;
  height: 160px;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--radiusL);
  background-color: var(--gray100);
  align-items: center;
  padding: var(--spacingXs) var(--spacingM);
  gap: var(--spacingS);

  > img {
    width: auto;
    max-height: 70px;
  }

  &:hover {
    background-color: var(--gray200);
  }

  @media screen and (min-width: 1128px) {
    height: 256px;

    > img {
      width: auto;
      height: 95px;
      max-height: none;
    }
  }
`;
