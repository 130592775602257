import dynamic from "next/dynamic";
import { NextImage } from "../index";
import { MiniCardLinkWrapper } from "./styles";

const Link = dynamic(() => import("../link/link"));

export const MiniCardWithLink = ({ title, icon, link }) => {
  return (
    <Link {...link}>
      <MiniCardLinkWrapper>
        {icon && <NextImage media={icon} alt={title} />}
        {title && <span>{title}</span>}
      </MiniCardLinkWrapper>
    </Link>
  );
};

export default MiniCardWithLink;
